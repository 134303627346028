import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessfulOrderBanner from "../sections/order/successful-order-banner"
import Container from "@material-ui/core/Container"

import { useIntl } from "gatsby-plugin-react-intl"

const SuccessfulOrderPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        description=""
        title={intl.formatMessage({
          id: "successfulOrderBanner_title",
        })}
      />
      <Container>
        <section className="successful-order-banner">
          <SuccessfulOrderBanner></SuccessfulOrderBanner>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SuccessfullOrder($language: String) {
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
  }
`

export default SuccessfulOrderPage
