import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardContent from "@material-ui/core/CardContent"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const SuccessfulOrderBanner = () => {
  return (
    <Card className="order-card my-5">
      <CardContent>
        <Box component="h2" textAlign="center" color="primary">
          <FormattedMessage
            id="successfulOrderBanner_title"
            defaultMessage="GRATULACJE!"
          />
        </Box>
        <Box component="p" textAlign="center" color="primary">
          <FormattedMessage
            id="successfulOrderBanner_firstLine"
            defaultMessage="Twoje zamówienie zostało złożone pomyślnie. Na podany adres email otrzymasz dane do logowania."
          />
          <br></br>
          <FormattedMessage
            id="successfulOrderBanner_secondLine"
            defaultMessage="Od teraz możesz bezpiecznie korzystać z naszych aplikacji."
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default SuccessfulOrderBanner
